//
//
//
//
//
//
//
//
//
//
//

//tab对应的组件
import Headers from "@/components/Headers.vue"
export default {
  data(){
    return {
      taglist : [
        {
          path : '/main/maintenance/plan',
          name : '维保计划',
          style : 'transform: translateX(0);;z-index : 4;'
        },
        {
          path : '/main/maintenance/order',
          name : '维保工单',
          style : 'transform : translateX(-10px);z-index : 3;'
        },
        {
          path : '/main/maintenance/mode',
          name : '维保模板',
          style : 'transform : translateX(-20px);z-index : 2;'
        },
        {
          path : '/main/maintenance/application',
          name : '设备申请',
          style : 'transform : translateX(-30px);z-index : 1;'
        }
      ]
    }
  },
  components : {
    Headers
  }
}
